import antdSA from 'antd/lib/locale-provider/pt_BR';
import saMessages from '../locales/pt_BR.json';

const saLang = {
  messages: {
    ...saMessages,
  },
  antd: antdSA,
  locale: 'pt',
};
export default saLang;
