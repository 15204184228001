import React from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { 
  useSelector,
  // useDispatch 
} from 'react-redux';
import { ThemeProvider } from 'styled-components';
import themes from '@iso/config/theme/theme.config';
// import actions from '@iso/redux/languageSwitcher/actions';
import AppLocale from '@iso/config/translation';

function getQueryString() {
  var sHref = window.location.href;
  var args = sHref.split("?");
  if (args[0] === sHref) {
    // 没有参数，直接返回空即可
    return "";
  }
  var arr = args[1].split("&");
  var obj = {};
  for (var i = 0; i < arr.length; i++) {
    var arg = arr[i].split("=");
    obj[arg[0]] = arg[1];
  }
  return obj;
}
// const { changeLanguage } = actions;

export default function AppProvider({ children }) {
  // const params = getQueryString();
  // params["lang"] && window.localStorage.setItem("lang", params["lang"]);
  // const dispatch = useDispatch();
  const { locale } = useSelector(state => state.LanguageSwitcher.language);
  const { themeName } = useSelector(state => state.ThemeSwitcher.changeThemes);
  
  // const browserLocale = navigator.language.split('-')[0];
  // const currentLocale = window.localStorage.getItem("lang") || browserLocale
  // const appLocaleKey = Object.keys(AppLocale).find(key => key === currentLocale) || locale;
  // // console.log("************************000:" + appLocaleKey)
  // dispatch(changeLanguage(appLocaleKey))
  const currentAppLocale = AppLocale[locale];
  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ThemeProvider theme={themes[themeName]}>{children}</ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  );
}
