import language from '@iso/config/language.config';

import englishLang from '@iso/assets/images/flag/uk.svg';
import chineseLang from '@iso/assets/images/flag/tw.svg';
import spanishLang from '@iso/assets/images/flag/spain.svg';
import frenchLang from '@iso/assets/images/flag/france.svg';
import italianLang from '@iso/assets/images/flag/italy.svg';
import japanLang from '@iso/assets/images/flag/jp.svg';
import indiaLang from '@iso/assets/images/flag/india.svg';
import koreanLang from '@iso/assets/images/flag/korean.svg';
import UAELang from '@iso/assets/images/flag/uae.svg';
import portugalLang from '@iso/assets/images/flag/portugal.svg';
import thLang from '@iso/assets/images/flag/th.svg';


const config = {
  defaultLanguage: language,
  options: [
    {
      languageId: 'english',
      locale: 'en',
      text: 'English',
      icon: englishLang,
    },
    {
      languageId: 'chinese',
      locale: 'zh',
      text: 'Chinese',
      icon: chineseLang,
    },
    {
      languageId: 'spanish',
      locale: 'es',
      text: 'Spanish',
      icon: spanishLang,
    },
    {
      languageId: 'french',
      locale: 'fr',
      text: 'French',
      icon: frenchLang,
    },
    {
      languageId: 'italian',
      locale: 'it',
      text: 'Italian',
      icon: italianLang,
    },
    {
      languageId: 'korean',
      locale: 'ko',
      text: 'Korean',
      icon: koreanLang,
    },
    {
      languageId: 'portuguese',
      locale: 'pt',
      text: 'Portuguese',
      icon: portugalLang,
    },
    {
      languageId: 'arabic',
      locale: 'ar',
      text: 'Arabic',
      icon: UAELang,
    },
    {
      languageId: 'japan',
      locale: 'ja',
      text: 'Japan',
      icon: japanLang,
    },
    {
      languageId: 'india',
      locale: 'hi',
      text: 'India',
      icon: indiaLang,
    },
    {
      languageId: 'thailand',
      locale: 'th',
      text: 'Thailand',
      icon: thLang,
    },
  ],
};

export function getCurrentLanguage(lang) {
  let selecetedLanguage = config.options[0];
  config.options.forEach(language => {
    if (language.locale === lang) {
      selecetedLanguage = language;
    }
  });
  return selecetedLanguage;
}
export default config;
