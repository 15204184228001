import Enlang from './entries/en-US';
import Zhlang from './entries/zh-Hans-CN';
import Salang from './entries/ar_SA';
import Itlang from './entries/it_IT';
import Eslang from './entries/es_ES';
import Frlang from './entries/fr_FR';
import Kolang from './entries/ko_KR';
import Ptlang from './entries/pt_BR';
import Hilang from './entries/hi_IN';
import Jalang from './entries/ja_JP';
import Thlang from './entries/th_TH';
const AppLocale = {
  en: Enlang,
  zh: Zhlang,
  ar: Salang,
  it: Itlang,
  es: Eslang,
  fr: Frlang,
  ko: Kolang,
  pt: Ptlang,
  hi: Hilang,
  ja: Jalang,
  th: Thlang,
};

export default AppLocale;
