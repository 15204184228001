import antdSA from 'antd/lib/locale-provider/ja_JP';
import saMessages from '../locales/ja_JP.json';

const saLang = {
  messages: {
    ...saMessages,
  },
  antd: antdSA,
  locale: 'ja',
};
export default saLang;
