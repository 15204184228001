import antdSA from 'antd/lib/locale-provider/ko_KR';
import saMessages from '../locales/ko_KR.json';

const saLang = {
  messages: {
    ...saMessages,
  },
  antd: antdSA,
  locale: 'ko',
};
export default saLang;
