import React, { lazy, Suspense } from 'react';

import {
  Route,
  // Redirect,
  BrowserRouter as Router,
  Switch,
  // useParams
} from 'react-router-dom';
// import { useSelector } from 'react-redux';

import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Loader from '@iso/components/utility/loader';


// ReactGA.initialize(siteConfig.google.analyticsKey);
// console.log('google GA:'+siteConfig.google.analyticsKey)

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    // component: lazy(() => import('@iso/containers/Pages/SignIn/SignIn')),
    component: lazy(() => import('@iso/containers/LandHome/LandHome')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import('@iso/containers/Pages/404/404')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import('@iso/containers/Pages/500/500')),
  },
];

function PrivateRoute({ children, ...rest }) {
  // const isLoggedIn = useSelector((state) => state.Auth.idToken);

  return (
    <Route
      {...rest}
      render={({ location }) => children

      }
    />
  );
}

export default function Routes() {


  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <PrivateRoute path="/dapp">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
