import antdSA from 'antd/lib/locale-provider/hi_IN';
import saMessages from '../locales/hi_IN.json';

const saLang = {
  messages: {
    ...saMessages,
  },
  antd: antdSA,
  locale: 'hi',
};
export default saLang;
