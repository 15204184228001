import antdSA from 'antd/lib/locale-provider/zh_TW';
import zhMessages from '../locales/zh-Hans.json';

const ZhLan = {
  messages: {
    ...zhMessages,
  },
  antd: antdSA,
  locale: 'zh-Hans-CN',
};
export default ZhLan;
