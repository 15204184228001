import React from 'react';
import { Provider } from 'react-redux';
// import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import GlobalStyles from '@iso/assets/styles/globalStyle';
import { store } from './redux/store';
import Boot from './redux/boot';
import Routes from './router';
import AppProvider from './AppProvider';

// const themes = {
//   dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
//   light: `${process.env.PUBLIC_URL}/light-theme.css`,
// };

// const prevTheme = window.localStorage.getItem("theme");

const App = () => (
  <Provider store={store}>
    <AppProvider>
      {/* <ThemeSwitcherProvider themeMap={themes} defaultTheme={prevTheme || "dark"}> */}
        <>
          <GlobalStyles />
          <Routes />
        </>
      {/* </ThemeSwitcherProvider> */}
    </AppProvider>
  </Provider>
);
Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App;
