import antdSA from 'antd/lib/locale-provider/th_TH';
import saMessages from '../locales/th_TH.json';

const saLang = {
  messages: {
    ...saMessages,
  },
  antd: antdSA,
  locale: 'th',
};
export default saLang;
